.siteLogo {
  margin-right: 20px;
  max-width: 250px;
  @media only screen and (max-width: 767px) {
    width: 12.14rem;
  }
}

.provided-by {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 5px;
  .para {
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: 20px;
    @media only screen and (min-width: 992px) {
      font-size: 1.78rem;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1rem;
    }
    @media only screen and (max-width: 767px) {
      text-align: center;
      // font-size: 0.7rem;
    }
  }
  .brandLogo {
    max-height: 45px;
    max-width: 370px;
    @media only screen and (max-width: 767px) {
      max-height: 20px;
    }
  }
}

.dropdown-locale {
  span {
    font-size: 1.5rem;
  }
  .dropdown-locale-item {
    &:active {
      background-color: transparent !important;
    }
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
