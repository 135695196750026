.content {
  h2,
  p {
    color: #000;
    @media only screen and (min-width: 768px) {
      text-align: left;
    }
    @media only screen and (max-width: 767px) {
      text-align: center;
    }
  }

  p {
    @media only screen and (min-width: 992px) {
      font-size: 2rem;
      margin: 1.42rem 0 3rem 0;
      line-height: 1.2;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.2rem;
      margin: 1rem 0 2.3rem 0;
      line-height: 1.2;
    }
  }
}
