.programbenefitscard {
  text-align: center;
  @media only screen and (max-width: 767px) {
    margin-top: 2rem;
  }
  &__image {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media only screen and (min-width: 992px) {
      width: 7.142rem;
      height: 7.142rem;
    }
    @media only screen and (max-width: 992px) {
      width: 4.142rem;
      height: 4.142rem;
    }
  }
  &__header {
    @media only screen and (min-width: 992px) {
      font-size: 1.5rem;
      margin: 1.5rem auto 0 auto;
      max-width: 16.42rem;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.3rem;
      max-width: 14.57rem;
      margin: 1.2rem auto 0 auto;
    }
  }
}
