.images {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 70% 30% 48% 52% / 53% 45% 55% 47%;
  position: relative;
  @media only screen and (min-width: 992px) {
    height: 34.71rem;
    width: 32.92rem;
    //margin: 0 auto;
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    height: 21.71rem;
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    height: 22.71rem;
    width: 84%;
    margin: 0 auto 2rem auto;
  }
  &::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 80%;
    left: 0;
    top: 0;
    border-radius: 70% 30% 48% 52% / 53% 45% 55% 47%;
    background: linear-gradient(307.25deg, #2eb695 15.07%, #1ea5de 84.98%);
    transform: translate(-2.142rem, -0.71rem);
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 80%;
    right: -10%;
    bottom: 0;
    border-radius: 70% 30% 48% 52% / 53% 45% 55% 47%;
    background: linear-gradient(307.25deg, #ebfaff 15.07%, #ebfaff 84.98%);
    z-index: -1;
  }
}
