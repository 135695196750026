.jumbotron {
  @media only screen and (min-width: 992px) {
    padding: 8.57rem 0 0 0;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 2rem;
  }
  p {
    @media only screen and (min-width: 992px) {
      font-size: 2rem;
      margin: 1.42rem 0 3rem 0;
      line-height: 1.2;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.2rem;
      margin: 1rem 0 2.3rem 0;
      line-height: 1.2;
    }
  }
}

.jumbotronimage {
  @media only screen and (min-width: 992px) {
    transform: translateY(-2.28rem);
  }
}

.socialmedia {
  display: flex;
  justify-content: flex-end;
  @media only screen and (min-width: 992px) {
    transform: translateY(-4.28rem);
  }
  a {
    display: inline-block;
    margin: 0 0.5rem;
  }
}

.socialmediaAmazon {
  @media only screen and (min-width: 992px) {
    width: 9.57rem;
  }
}
.socialmediaGoogleplay {
  @media only screen and (min-width: 992px) {
    width: 11.42rem;
  }
}
.socialmediaAppstore {
  @media only screen and (min-width: 992px) {
    width: 10.42rem;
  }
}
