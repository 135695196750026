.content {
  text-align: center;
  max-width: 32.85rem;
  margin: 0 auto;
  h2,
  p {
    color: #fff;
  }

  p {
    @media only screen and (min-width: 992px) {
      font-size: 2rem;
      margin: 1.42rem 0 3rem 0;
      line-height: 1.2;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.2rem;
      margin: 1rem 0 2.3rem 0;
      line-height: 1.2;
    }
  }
}
